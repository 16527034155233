import React, { useState, useEffect } from "react";
import { UserOutlined, EditOutlined, DollarOutlined, UserAddOutlined, UserSwitchOutlined, UnlockOutlined, ScheduleOutlined, CalendarOutlined, PlusCircleOutlined, UnorderedListOutlined} from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Layout, Menu, Divider } from "antd";
import "./styles.css";
import Brand from "../Brand/Brand";
import theme from "../../../styles/theme";
import Title from "../Title/Title";
import UserArea from "../UserArea/UserArea";
import getItem from "../../../service/antd/layout/getSiderItem";
import logout from "../../../service/api/auth/logout";
import { defaultUser, useUserContext } from "../../../service/context/user/UserContext";
import SignatureContent from "../../signature/SignatureContent/SignatureContent";
import UserContent from "../../users/UserContent/UserContent";
import UserDivider from "../../users/UserDivider/UserDivider";
import { ContentContext } from "../../../service/context/content/ContentContext";
import getSubMenuItems from "../../../service/antd/layout/getSubMenuItems";
import { Navigate } from "react-router-dom";
import PaymentContent from "../../payment/PaymentContent/PaymentContent";
import PaymentDivider from "../../payment/PaymentDivider/PaymentDivider";

const { Header, Content, Footer, Sider } = Layout;

type MenuItem = Required<MenuProps>["items"][number];

const StandardLayout = (props: any) => {
    const { user } = useUserContext();

    const [collapsed, setCollapsed] = useState(false);
    const [subtitle, setSubtitle] = useState("Assinatura");
    const [content, setContent] = useState(<SignatureContent />);
    const [subContent, setSubContent] = useState("");
    const [menuDivider, setMenuDivider] = useState(<></>);
    const [subMenuItems, setSubMenuItems] = useState([] as MenuItem[]);

    const onClickChangeContent = (newContent: JSX.Element, menuDivider: JSX.Element, newSubtitle: string, subMenuItems: any) => {
        setContent(newContent);
        setSubtitle(newSubtitle);
        setMenuDivider(menuDivider);
        setSubMenuItems(subMenuItems);
    };

    const onClickChangeSubmenu = (newSubContent: string) => {
        setSubContent(newSubContent);
    };

    // const userItems = getUserSiderItems(onClickChangeSubmenu);
    const userItems: MenuItem[] = [
        getSubMenuItems("Funcionários", "1", onClickChangeSubmenu, "EmployeeList", <UserOutlined />, user.permission < 5),
        getSubMenuItems("Cadastrar", "2", onClickChangeSubmenu, "UserForm", <UserAddOutlined />, user.permission < 5),
        getSubMenuItems("Listar", "3", onClickChangeSubmenu, "UserList", <UserSwitchOutlined />, user.permission < 5),
        getSubMenuItems("Senhas", "4", onClickChangeSubmenu, "PasswordResetForm", <UnlockOutlined />, user.permission < 5),
        getSubMenuItems("Escala", "5", onClickChangeSubmenu, "Schedule", <ScheduleOutlined />, user.permission < 5),
        getSubMenuItems("Quinzenais", "6", onClickChangeSubmenu, "Journal", <CalendarOutlined />, user.permission < 5),
    ];

    const paymentItems: MenuItem[] = [
        // getSubMenuItems("Cadastrar", "1", onClickChangeSubmenu, "PaymentForm", <PlusCircleOutlined />, user.payment_permission === 0),
        // getSubMenuItems("Listar", "2", onClickChangeSubmenu, "PaymentList", <UnorderedListOutlined />, user.payment_permission === 0),
    ];

    const siderItems: MenuItem[] = [
        getItem("Assinatura", "1", onClickChangeContent, <SignatureContent />, <></>, null, "Assinatura", <EditOutlined />),
        getItem("Recheque", "2", onClickChangeContent, <PaymentContent />, <PaymentDivider />, paymentItems, "Recheque", <DollarOutlined />, user.payment_permission === 0),
        getItem("Usuários", "3", onClickChangeContent, <UserContent />, <UserDivider />, userItems, "Usuários", <UserOutlined />, user.permission < 5),
    ];

    useEffect(() => {}, [content, collapsed]);

    const items: MenuProps["items"] = [
        {
            key: "0",
            label: <Brand size={1} />,
        },
        {
            key: "1",
            label: <Title subtitle={subtitle} />,
            className: "title",
        },
        {
            key: "2",
            label: <UserArea />,
            className: "last",
            onClick: logout,
        },
    ];

    return user === defaultUser && user.changed ? (
        <Navigate to={"/login"} />
    ) : (
        <Layout className="h-100" hasSider style={{ backgroundColor: theme.globalBackground }}>
            <ContentContext.Provider value={{ content: subContent, setContent: setSubContent }}>
                <Sider id="sider" className="drop-shadow" collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)} style={{ backgroundColor: theme.secondary, position: "fixed", left: 0, top: 64, bottom: 0 }}>
                    <Menu theme="dark" mode="inline" items={siderItems} style={{ display: "block", backgroundColor: theme.secondary }} />
                    {menuDivider}
                    <Menu theme="dark" mode="inline" items={subMenuItems} style={{ display: "block", backgroundColor: theme.secondary }} />
                </Sider>
                <Layout style={{ padding: "12px 24px 24px", background: theme.white }}>
                    <Header className="header" style={{ backgroundColor: theme.primary, position: "fixed", left: 0, right: 0, top: 0 }}>
                        <Menu id="header" theme="dark" mode="horizontal" items={items} style={{ width: "100%", backgroundColor: theme.primary }} />
                    </Header>
                    <Content
                        style={{
                            padding: 24,
                            margin: 0,
                            marginLeft: collapsed ? "80px" : "200px",
                            marginTop: 64,
                            transition: "0.2s",
                            // height: "100%",
                            background: "white",
                            overflow: "auto",
                        }}
                        className="drop-shadow">
                        {content}
                    </Content>
                </Layout>
            </ContentContext.Provider>
        </Layout>
    );
};

export default StandardLayout;
