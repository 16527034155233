import React, { useState, useEffect, createElement } from "react";
import theme from "../../../styles/theme";
import "../../../styles/contentStyles.css";
import UserForm from "../UserForm/UserForm";
import UserList from "../UserList/UserList";
import { ContentContext, useContentContext } from "../../../service/context/content/ContentContext";
import Swal from "sweetalert2";
import PasswordResetForm from "../PasswordResetForm/PasswordResetForm";
import { useUserContext } from "../../../service/context/user/UserContext";
import Schedule from "../Schedule/Schedule";
import Journal from "../Journal/Journal";
import EmployeeList from "../EmployeeList/EmployeeList";

const UserContent = () => {
    const [title, setTitle] = useState("Usuários");

    const { user } = useUserContext();
    const { content, setContent } = useContentContext();
    useEffect(() => {
        if (content !== "UserList" && content !== "UserForm") {
            setContent("UserList");
        }
    }, []);

    useEffect(() => {
        if (content === "UserForm") {
            setTitle("Cadastrar usuários");
        } else if (content === "UserList") {
            setTitle("Usuários");
        } else if (content === "PasswordResetForm") {
            setTitle("Atualizar senha");
        } else if (content === "Schedule") {
            setTitle("Escala");
        }
    }, [content]);

    const handleAlert = (status: string) => {
        if (status === "success") {
            Swal.fire({
                icon: "success",
                title: "Cadastrado com sucesso",
                timer: 2000,
                showConfirmButton: true,
                confirmButtonColor: "#7dba05",
            });
        } else if (status === "error") {
            Swal.fire({
                icon: "error",
                title: "Falha no cadastro",
                timer: 2000,
                showCloseButton: true,
                confirmButtonColor: "#f6394d",
            });
        }
    };

    return (
        <div className="content">
            <div className="content-title" style={{ backgroundColor: theme.secondary }}>
                <h3>{title}</h3>
            </div>
            <div className="main">
                <ContentContext.Consumer>
                    {() => {
                        // if (user.permission < 5) return <></>;
                        if (content == "UserForm") {
                            return <UserForm handleAlert={handleAlert} />;
                        } else if (content === "UserList") {
                            // setTitle("Usuários");
                            return <UserList />;
                        } else if (content === "PasswordResetForm") {
                            // setTitle("Atualizar senha");
                            return <PasswordResetForm />;
                        } else if (content === "Schedule"){
                            return <Schedule/>
                        } else if (content === "Journal") {
                            return <Journal/>;
                        } else if (content === "EmployeeList") {
                            return <EmployeeList/>;
                        }
                    }}
                </ContentContext.Consumer>
            </div>
        </div>
    );
};

export default UserContent;
