import React, { useEffect } from "react";
import { DollarOutlined } from "@ant-design/icons";
import { Divider } from "antd";
import "./styles.css";

const PaymentDivider = (props: any) => {
    return (
        <Divider plain orientation="left">
            <DollarOutlined />
        </Divider>
    );
};

export default PaymentDivider;
