import { createContext, useContext } from "react";

export type Content = {
    content: string;
    setContent: any;
};

export const ContentContext = createContext<Content>({
    content: "",
    setContent: () => {},
});

export const useContentContext = () => useContext(ContentContext);
