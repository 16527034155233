import React, { useState } from "react";
import { BrowserRouter } from "react-router-dom";
import Router from "./Routes";
import { UserContext, defaultUser } from "./service/context/user/UserContext";

const App: React.FC = () => {
    const [user, setUser] = useState(defaultUser);
    return (
        <>
            <BrowserRouter>
                <UserContext.Provider value={{ user, setUser }}>
                    <Router />
                </UserContext.Provider>
            </BrowserRouter>
        </>
    );
};

export default App;
