import React from "react";
import { Navigate } from "react-router-dom";
import { getCookie } from "typescript-cookie";
import Auth from "../../components/app/Auth/Auth";
import theme from "../../styles/theme";
import Index from "../Index/Index";
import "./styles.css";

const Login = (props: any) => {
    const tokenExists = getCookie("token");

    if (tokenExists && props.attempt !== 0) return <Navigate to={"/"} />;
    else
        return (
            <div id="loginPage" style={{ background: theme.gradientGreen }}>
                <Auth />
            </div>
        );
};

export default Login;
