import axios from "axios";

const addEmployee = async (name: string) => {
    const url = "https://prosif.api.user.sif.org.br/api/employee";
    // const url = "http://localhost:8000/api/employee";

    try {
        const { data } = await axios.post(url, {
            name: name,
        });

        return data;
    } catch (err) {
        // console.log(err);
    }
};

export default addEmployee;
