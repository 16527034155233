import axios from "axios";

const getEmployees = async () => {
    const url = "https://prosif.api.user.sif.org.br/api/employee?attr=name"

    try {
        const { data } = await axios.get(url);

        return data;
    } catch (err) {
        console.log(err);
    }
};

export default getEmployees;
