import axios from "axios";

const updateUser = async (user: string, userData: {}) => {
    const url = "https://prosif.api.user.sif.org.br/api/user/";
    // const url = "http://localhost:8000/api/user/";

    try {
        const { data } = await axios.put(url + user, userData);

        return data;
    } catch (err) {
        console.log(err);
    }
};

export default updateUser;
