import React, { useEffect } from "react";
import { UserOutlined } from "@ant-design/icons";
import { Divider } from "antd";
import "./styles.css";

const UserDivider = (props: any) => {
    return (
        <Divider plain orientation="left">
            <UserOutlined />
        </Divider>
    );
};

export default UserDivider;
