import axios from "axios";
import { setCookie, getCookie } from "typescript-cookie";

type LoginResponse = {
    token: string;
};

const login = async (email: string, password: string, handleProgress = (status: any) => {}, handleError = (status: any) => {}) => {
    const url = "https://prosif.api.user.sif.org.br/api/login";
    const redirectUrl = "https://prosif.sif.org.br";
    // const url = "http://localhost:8000/api/login";
    // const redirectUrl = "http://localhost:3000";

    try {
        handleError("none");
        handleProgress("block");

        const { data } = await axios.post<LoginResponse>(url, {
            email: email,
            password: password,
        });

        handleProgress("none");

        setCookie("token", data.token, { expires: 1, secure: true });
        window.location.href = redirectUrl;
    } catch {
        handleProgress("none");
        handleError("block");
    }
};

export default login;
