import React, { useState, useEffect } from "react";
import { Button, Divider, Form, Input, Select, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import "../../../styles/formStyles.css";
import getUsers from "../../../service/api/user/getUsers";
import updateUser from "../../../service/api/user/updateUser";
import Swal from "sweetalert2";

const initialValues = {
    user: null,
    password: "",
};

const PasswordResetForm = () => {
    const [options, setOptions] = useState([]);
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getUsers().then((res) => {
            const newOptions = res.map((user: any) => {
                return {
                    value: user.id,
                    label: user.email + " - " + user.employee.name,
                };
            });
            setOptions(newOptions);
        });
    }, []);

    const handleAlert = (status: string) => {
        if (status === "success") {
            Swal.fire({
                icon: "success",
                title: "Senha alterada com suceso",
                timer: 2000,
                showConfirmButton: true,
                confirmButtonColor: "#7dba05",
            });
        } else if (status === "error") {
            Swal.fire({
                icon: "error",
                title: "Falha na alteração de senha",
                timer: 2000,
                showCloseButton: true,
                confirmButtonColor: "#f6394d",
            });
        }
    };

    const handlePasswordReset = (values: any) => {
        setLoading(true);
        updateUser(values.user, {
            password: values.password,
        })
            .then((res) => {
                setLoading(false);
                handleAlert("success");
                form.resetFields();
            })
            .catch((err) => {
                setLoading(false);
                handleAlert("error");
            });
    };

    const antIcon = <LoadingOutlined style={{ fontSize: 36 }} spin />;

    return (
        <Form
            form={form}
            layout="vertical"
            wrapperCol={{ xs: 24 }}
            onFinish={handlePasswordReset}
            initialValues={initialValues}
            // onFinishFailed={onFinishFailed}
            // validateMessages={validateMessages}
        >
            <Divider orientation="left">
                <span className="formDivider">Atualização de senha</span>
            </Divider>

            <Input.Group compact>
                <Input disabled className="label" defaultValue="Usuário" />
                <Form.Item name="user" style={{ width: "75%", marginBottom: 10 }} className="form-item" rules={[{ required: true }]}>
                    <Select style={{ width: "100%" }} options={options} />
                </Form.Item>
            </Input.Group>

            <Form.Item name="password" rules={[{ required: true, message: "Digite a nova senha" }]}>
                <Input.Password addonBefore="Senha" placeholder="Senha" />
            </Form.Item>

            <div className="center">
                <Button className="confirmButton registerButton" htmlType="submit">
                    Atualizar
                </Button>
            </div>

            <div style={{ display: loading ? "block" : "none" }}>
                <Spin className="center" indicator={antIcon} style={{ marginTop: 10 }} />
            </div>
        </Form>
    );
};

export default PasswordResetForm;
