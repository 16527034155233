import type { MenuProps } from "antd";
type MenuItem = Required<MenuProps>["items"][number];

function getItem(
    label: React.ReactNode,
    key: React.Key,
    onClick: any,
    newContent: JSX.Element,
    menuDivider: JSX.Element,
    subMenuItems: any,
    subTitle: string,
    icon?: React.ReactNode,
    disable?: boolean,
    children?: MenuItem[]
): MenuItem {
    return {
        key,
        icon,
        children,
        label,
        disabled: disable,
        onClick: () => onClick(newContent, menuDivider, subTitle, subMenuItems),
    } as MenuItem;
}

export default getItem;
