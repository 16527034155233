import { Button } from "antd";
import React, { useEffect, useState } from "react";
import theme from "../../../styles/theme";
import Signature from "../Signature/Signature";
import SignatureForm from "../SignatureForm/SignatureForm";
import "../../../styles/contentStyles.css";
import "./styles.css";
import Swal from "sweetalert2";

const SignatureContent = () => {
    const [name, setName] = useState("");
    const [formation, setFormation] = useState("");
    const [position, setPosition] = useState("");
    const [email, setEmail] = useState("");
    const [number, setNumber] = useState("");
    const [optNumber, setOptNumber] = useState("");
    const [model, setModel] = useState("");
    const [visibility, setVisibility] = useState(false);
    const [display, setDisplay] = useState("none");

    const handleSubmit = (values: any) => {
        setName(values.Nome);
        setFormation(values.Formação);
        setPosition(values.Cargo);
        setEmail(values.Email);
        setNumber(values.Telefone);
        setOptNumber(values.TelefoneOpcional);
        setModel(values.model);
        setVisibility(true);
    };

    const copy = (element_id: string) => {
        // Cria uma nova div que será utilizada para copiar as informações e mantendo a formatação
        var aux = document.createElement("div");
        aux.setAttribute("contentEditable", "true");

        // Recupera a div a copiar e a introduz dentro da nova div
        // @ts-ignore: Object is possibly 'null'.
        aux.innerHTML = document.getElementById(element_id).innerHTML;

        // Configura o comando que será utilizado quando a div estiver em foco.
        aux.setAttribute("onfocus", "document.execCommand('selectAll',false,null)");

        // Retira o id body para remover o background
        var body = document.getElementById("root");
        // @ts-ignore: Object is possibly 'null'.
        body.id = "";

        // Adiciona a div ao body, a copia e depois remove a mesma do body
        document.body.appendChild(aux);
        aux.focus();
        document.execCommand("copy");
        document.body.removeChild(aux);

        // Configura o id body removido anteriormente para não causar alterações no estilo
        // @ts-ignore: Object is possibly 'null'.
        body.id = "root";

        Swal.fire({
            icon: "success",
            text: "Assinatura copiada para área de transferência",
            // confirmButtonColor: '#1b8233',
            showConfirmButton: false,
            timer: 1000,
        });
    };

    useEffect(() => {
        function handleResize() {
            if (window.innerWidth >= 850 && visibility === true) setDisplay("block");
            else if (window.innerWidth < 850 && visibility === true) setDisplay("none");
        }
        window.addEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        if (visibility === true) {
            if (window.innerWidth >= 850) setDisplay("block");
            else if (window.innerWidth < 850) setDisplay("none");
        }
    }, [visibility]);

    return (
        <div className="content">
            <div className="content-title" style={{ backgroundColor: theme.secondary }}>
                <h3> Como utilizar </h3>
                <p>
                    Preencha com seus dados para gerar sua assinatura. Os campos de e-mail e telefone são pré-preenchidos por padrão, altere se houver
                    opções mais adequadas. O segundo campo de telefone de contato é opcional. Nos campos de telefone digite apenas números. Para
                    evitar erros de formatação, ao configurar a nova assinatura pela primeira vez, exclua a antiga ao invés de somente editar.
                </p>
            </div>
            <div className="main">
                <SignatureForm onFinish={handleSubmit} />
                <Button className="copy-button" onClick={() => copy("copy")} style={{ display: visibility ? "block" : "none" }}>
                    Copiar
                </Button>
                <div id="signature" style={{ display: visibility ? "flex" : "none" }}>
                    <Signature
                        name={name}
                        formation={formation}
                        position={position}
                        email={email}
                        number={number}
                        optNumber={optNumber}
                        model={model}
                        display={display}
                    />
                </div>
            </div>
        </div>
    );
};

export default SignatureContent;
