import React from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import theme from "../../../styles/theme";

const PageSpin = () => {
    const antIcon = <LoadingOutlined style={{ fontSize: 72 }} spin />;

    return (
        <div style={{ position: "fixed", top: "50%", left: "50%" }}>
            <Spin size="large" indicator={antIcon} style={{ color: theme.staticGreen }} />
        </div>
    );
};

export default PageSpin;
