import React, { useEffect, useState } from "react";
import { getCookie } from "typescript-cookie";
import { Navigate } from "react-router-dom";
import axios from "axios";
import me from "../../../service/api/user/me";
import PageSpin from "../PageSpin/PageSpin";
import Login from "../../../pages/Login/Login";
import { useUserContext } from "../../../service/context/user/UserContext";

interface Props {
    component: React.ComponentType;
    path?: string;
}

const PrivateRoute: React.FC<Props> = ({ component: RouteComponent }) => {
    const [loading, setLoading] = useState(true);
    const [isAuth, setAuth] = useState(false);

    const { setUser } = useUserContext();

    axios.defaults.headers.common["Authorization"] = "Bearer " + getCookie("token");
    me()
        .then((res) => {
            let response = res;
            setAuth(true);
            setLoading(false);
        })
        .catch((err) => {
            setAuth(false);
            setLoading(false);
        });

    return loading ? <PageSpin /> : isAuth ? <RouteComponent /> : <Login attempt={1} />;
};

export default PrivateRoute;
