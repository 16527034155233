import React, { useRef, useState, useEffect } from "react";
import type { InputRef } from "antd";
import { Table, Form, Typography } from "antd";
import { EditOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
import getUsers from "../../../service/api/user/getUsers";
import "./styles.css";
import { getColumnSearchProps } from "../../../service/antd/table/searchAndFilters";
import { EditableCell, getOnCellProps } from "../../../service/antd/table/editableCell";
import getColumnProps from "../../../service/antd/table/columnsProps";
import getEmployeeWithoutUser from "../../../service/api/employee/getEmployeeWithoutUser";
import updateUser from "../../../service/api/user/updateUser";
import { useUserContext } from "../../../service/context/user/UserContext";

interface DataType {
    key: string;
    name: string;
    employeeId: string;
    email: string;
    activated: string;
    permission: string;
    payment_permission: string;
}

let startData: DataType[];

const convertPermission = (permission: string | number) => {
    if (typeof permission === "string") {
        if (permission === "Nenhum") return 0;
        if (permission === "Padrão") return 1;
        if (permission === "Administrativo") return 5;
    } else {
        if (permission === 0) return "Nenhum";
        if (permission === 1) return "Padrão";
        if (permission === 5) return "Administrativo";
    }
};

const permissionsOptions = [
    {
        value: "Nenhum",
        label: "Nenhum",
    },
    {
        value: "Padrão",
        label: "Padrão",
    },
    {
        value: "Administrativo",
        label: "Administrativo",
    },
];

const activatedPermissions = [
    {
        value: "Sim",
        label: "Sim",
    },
    {
        value: "Não",
        label: "Não",
    },
];

const UserList = () => {
    const [data, setData] = useState(startData);
    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const searchInput = useRef<InputRef>(null);
    const [loading, setLoading] = useState(true);
    const [form] = Form.useForm();
    const [editingKey, setEditingKey] = useState("");
    const [employeeOptions, setEmployeeOptions] = useState([{}]);

    const { user, setUser } = useUserContext();

    useEffect(() => {
        getUsers().then((res) => {
            startData = res.map((user: any) => {
                return {
                    key: user.id,
                    name: user.employee.name,
                    employeeId: user.employee_id,
                    email: user.email,
                    activated: user.activated ? "Sim" : "Não",
                    permission: convertPermission(user.permission),
                    payment_permission: convertPermission(user.payment_permission),
                };
            });
            setData(startData);
            setLoading(false);
        });
    }, []);

    useEffect(() => {
        getEmployeeWithoutUser().then((res) => {
            let options = res.map((e: any) => {
                return {
                    value: e.id + " - " + e.name,
                    label: e.name,
                };
            });
            setEmployeeOptions(options);
        });
    }, [data]);

    const isEditing = (record: DataType) => record.key === editingKey;

    const edit = (record: Partial<DataType> & { key: React.Key }) => {
        form.setFieldsValue({ name: "", age: "", address: "", ...record });
        setEditingKey(record.key);
    };

    const cancel = () => {
        setEditingKey("");
    };

    const save = async (key: React.Key) => {
        try {
            const row = (await form.validateFields()) as DataType;

            const newData = [...data];
            const index = newData.findIndex((item) => key === item.key);
            if (index > -1) {
                const item = newData[index];
                const name = row.name.split(" - ");
                newData.splice(index, 1, {
                    ...item,
                    ...row,
                    name: name[1] || item.name,
                });
                const empId = name.length > 1 ? name[0] : item.employeeId;
                updateUser(item.key, {
                    employee_id: empId,
                    email: row.email,
                    permission: convertPermission(row.permission),
                    payment_permission: convertPermission(row.payment_permission),
                    activated: row.activated === "Sim" ? 1 : 0,
                });
                if (parseInt(item.key) === user.id) {
                    setUser({
                        ...user,
                        employee_id: empId,
                        email: row.email,
                        permission: convertPermission(row.permission),
                        payment_permission: convertPermission(row.payment_permission),
                        activated: row.activated === "Sim" ? 1 : 0,
                    });
                }
                setData(newData);
                setEditingKey("");
            } else {
                newData.push(row);
                setData(newData);
                setEditingKey("");
            }
        } catch (errInfo) {
            console.log("Validate Failed:", errInfo);
        }
    };

    const columns: ColumnsType<DataType> = [
        {
            ...getColumnProps("E-mail", "email"),
            ...getColumnSearchProps("email", searchInput, searchText, searchedColumn, setSearchText, setSearchedColumn),
            onCell: (record: DataType) => ({
                ...getOnCellProps("email", record, "e-mail", "text", editingKey, isEditing),
            }),
            sorter: (a, b) => a.email.localeCompare(b.email),
            sortDirections: ["descend", "ascend"],
            showSorterTooltip: false,
            width: "200px",
            fixed: "left",
        },
        {
            ...getColumnProps("Nome", "name"),
            ...getColumnSearchProps("name", searchInput, searchText, searchedColumn, setSearchText, setSearchedColumn),
            onCell: (record: DataType) => ({
                ...getOnCellProps("name", record, "Nome", "select", editingKey, isEditing, employeeOptions, "name"),
            }),
            sorter: (a, b) => a.name.localeCompare(b.name),
            sortDirections: ["descend", "ascend"],
            showSorterTooltip: false,
            width: "150px",
        },
        {
            ...getColumnProps("Acesso usuários", "permission"),
            onCell: (record: DataType) => ({
                ...getOnCellProps("permission", record, "acesso usuários", "select", editingKey, isEditing, permissionsOptions, "permission"),
            }),
            onFilter: (value, record: DataType) => convertPermission(record.permission) === value,
            filters: [
                {
                    text: "Nenhum",
                    value: 0,
                },
                {
                    text: "Padrão",
                    value: 1,
                },
                {
                    text: "Administrativo",
                    value: 5,
                },
            ],
            width: "150px",
        },
        {
            ...getColumnProps("Acesso recheque", "payment_permission"),
            onCell: (record: DataType) => ({
                ...getOnCellProps(
                    "payment_permission",
                    record,
                    "acesso recheque",
                    "select",
                    editingKey,
                    isEditing,
                    permissionsOptions,
                    "payment_permission"
                ),
            }),
            onFilter: (value, record: DataType) => convertPermission(record.payment_permission) === value,
            filters: [
                {
                    text: "Nenhum",
                    value: 0,
                },
                {
                    text: "Padrão",
                    value: 1,
                },
                {
                    text: "Administrativo",
                    value: 5,
                },
            ],
            width: "150px",
        },
        {
            ...getColumnProps("Ativo", "activated"),
            onCell: (record: DataType) => ({
                ...getOnCellProps("activated", record, "ativo", "select", editingKey, isEditing, activatedPermissions, "activated"),
            }),
            onFilter: (value, record: DataType) => (record.activated === "Sim" ? true : false) === value,
            filters: [
                {
                    text: "Sim",
                    value: true,
                },
                {
                    text: "Não",
                    value: false,
                },
            ],
            width: "100px",
        },
        {
            ...getColumnProps("Ações", "actions"),
            render: (_: any, record: DataType) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
                        <Typography.Link onClick={() => save(record.key)} style={{ marginRight: 8, float: "left" }}>
                            <CheckOutlined />
                        </Typography.Link>
                        <Typography.Link onClick={cancel} style={{ float: "right" }}>
                            <CloseOutlined />
                        </Typography.Link>
                    </span>
                ) : (
                    <Typography.Link disabled={editingKey !== ""} onClick={() => edit(record)}>
                        <EditOutlined style={{ width: "100%" }} />
                    </Typography.Link>
                );
            },
            width: "80px",
            fixed: "right",
        },
    ];

    return (
        <Form form={form} component={false}>
            <Table
                columns={columns}
                dataSource={data}
                components={{
                    body: {
                        cell: EditableCell,
                    },
                }}
                rowClassName={(record, index) => (index % 2 === 0 ? "table-row-light" : "table-row-dark")}
                bordered
                scroll={{ x: "1500" }}
                pagination={{
                    onChange: cancel,
                    pageSize: 6,
                }}
                loading={loading}
            />
        </Form>
    );
};

export default UserList;
