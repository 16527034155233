import axios from "axios";

const getEmployeeWithoutUser = async () => {
    const url = "https://prosif.api.user.sif.org.br/api/employeeWithotUser?attr=name";
    // const url = "http://localhost:8000/api/employeeWithotUser?attr=name";

    try {
        const { data } = await axios.get(url);

        return data;
    } catch (err) {
        console.log(err);
    }
};

export default getEmployeeWithoutUser;
