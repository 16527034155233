import { ColumnType } from "antd/es/table";

function getColumnProps<Type>(title: string, dataIndex: string): ColumnType<Type> {
    return {
        title: title,
        dataIndex: dataIndex,
        key: dataIndex,
    };
}

export default getColumnProps;
