import React from "react";
import { Routes, Route } from "react-router-dom";
import PrivateRoute from "./components/app/PrivateRoute/PrivateRoute";
import Index from "./pages/Index/Index";
import Login from "./pages/Login/Login";

const Router: React.FC = () => {
    return (
        <Routes>
            <Route path="/" element={<PrivateRoute component={Index} />} />
            <Route path="/login" element={<Login attempt={0} />} />
        </Routes>
    );
};

export default Router;
