import React, { useEffect } from "react";
import { text } from "stream/consumers";
import data from "./data.json";
import "./styles.css";

const Signature = (props: any) => {
    const model = props.model == 0 ? data.sif : data.embrapii;
    const nameColor = props.model == 0 ? "rgb(0, 117, 54)" : "#1169b1";
    const textColor = props.model == 0 ? "rgb(41, 181, 28)" : "#2a90c1";
    const phoneColor = props.model == 0 ? "rgb(0, 125, 67)" : "#2a90c1";
    // const correctMargin = props.model == 0 || props.formation != "" ? "56px" : "24px";
    let correctMargin;
    if (props.model == 0) {
        if (props.formation == "") correctMargin = "64px";
        else correctMargin = "84px";
    } else {
        if (props.formation === "") correctMargin = "0px";
        else correctMargin = "25px";
    }

    useEffect(() => {}, [correctMargin]);

    return (
        <div id="copy" className="signatureArea" style={{ marginTop: "10px", display: props.display === "block" ? "block" : "none" }}>
            <div className="mainSign">
                <img src={model.layout.topBar} width="600" alt="" />
                <div>
                    <div id="content">
                        <div className="infoArea">
                            <div style={{ color: nameColor }} className="name">
                                {props.name}
                            </div>
                            <div style={{ color: textColor }} className="text">
                                {props.formation}
                            </div>
                            <div style={{ color: textColor }} className="text">
                                {props.position}
                            </div>
                            <div style={{ color: textColor }} className="text">
                                {props.email}
                            </div>
                            <div style={{ color: phoneColor }} className="phone">
                                {props.number}
                            </div>
                            <div style={{ color: phoneColor }} className="phone">
                                {props.optNumber}
                            </div>
                        </div>
                        <div className="iconArea">
                            <a href={model.refs.site} className="iconLink">
                                <img src={model.icons.site} className="iconImg" />
                            </a>
                            <a href={model.refs.instagram} className="iconLink">
                                <img src={model.icons.instagram} className="iconImg" />
                            </a>
                            <a href={model.refs.linkedin} className="iconLink">
                                <img src={model.icons.linkedin} className="iconImg" />
                            </a>
                            <a href={model.refs.facebook} className="iconLink">
                                <img src={model.icons.facebook} className="iconImg" />
                            </a>
                        </div>
                        <img style={{ marginTop: correctMargin, float: "right", width: "240px" }} src={model.layout.brand} />
                        <img style={{ marginBottom: "32px", width: "600px" }} src={model.layout.topBar} />
                        <img style={{ width: "600px" }} src={model.layout.warning} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Signature;
