import React from "react";
import { Form, Input, Select } from "antd";
import { PlusOutlined } from "@ant-design/icons";

const TableSelect = (props: any) => {
    return (
        <Form.Item name={props.fieldName} style={{ margin: "0 auto" }} className="form-item" rules={[{ required: true }]}>
            <Select options={props.options}  style={{ width: "100%" }} />
        </Form.Item>
    );
};

export default TableSelect;
