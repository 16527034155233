import { createContext, useContext } from "react";

export type User = {
    id: number;
    email: string;
    permission: number;
    payment_permission: number;
    employee_id: number;
    name: string;
    activated: boolean;
    changed: boolean;
};

export type UserContent = {
    user: User;
    setUser: any;
};

export const defaultUser = {
    id: -1,
    name: "",
    permission: -1,
    payment_permission: -1,
    employee_id: -1,
    email: "",
    activated: false,
    changed: false,
};

export const UserContext = createContext<UserContent>({
    user: defaultUser,
    setUser: () => {},
});
export const useUserContext = () => useContext(UserContext);
