import axios from "axios";

const getUsers = async () => {
    const url = "https://prosif.api.user.sif.org.br/api/user";
    // const url = "http://localhost:8000/api/user";

    try {
        const { data } = await axios.get(url);

        return data;
    } catch (err) {
        // console.log(err);
    }
};

export default getUsers;
