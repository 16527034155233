import React from "react";
import { Input, InputNumber, Form } from "antd";
import TableSelect from "../../../components/app/TableSelect/TableSelect";

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
    editing: boolean;
    dataIndex: string;
    inputType: "number" | "text" | "select" | "select2";
    record: any;
    index: number;
    children: React.ReactNode;
    options?: any;
    fieldName?: string;
    addConfig?: any;
}

export function getOnCellProps<Type>(dataIndex: keyof Type, record: Type, title: string, inputType: string, editingKey: string, isEditing: any, options?: any, fieldName?: string) {
    return {
        dataIndex: dataIndex,
        title: title,
        record,
        inputType: inputType,
        editing: isEditing(record, editingKey),
        options: options,
        fieldName: fieldName,
    };
}

export const EditableCell: React.FC<EditableCellProps> = ({ editing, dataIndex, title, inputType, record, index, children, options, fieldName, ...restProps }) => {
    let inputNode;
    if (inputType === "number") {
        inputNode = <InputNumber />;
    } else if (inputType === "text") {
        inputNode = <Input />;
    } else if (inputType === "select") {
        inputNode = <TableSelect fieldName={fieldName} options={options} />;
    }
    // else if (inputType === "select2") {
    //     inputNode = (
    //         <TableSelectWithAdd
    //             options={options}
    //             fieldName={fieldName}
    //             holder={addConfig.holder}
    //             value={addConfig.value}
    //             onValueChange={addConfig.onValueChange}
    //             addItem={addConfig.addItem}
    //         />
    //     );
    // }

    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{ margin: 0 }}
                    rules={[
                        {
                            required: true,
                            message: ` ${title}!`,
                        },
                    ]}>
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};
