import React from "react";
import { Form, Button, Select, Input, Radio } from "antd";
import "../../../styles/formStyles.css";
import "./styles.css";

const options = [
    {
        value: "0",
        label: "SIF",
    },
    {
        value: "1",
        label: "Embrapii",
    },
];

const radioOptions = [
    {
        value: "",
        label: "Outros",
    },
    {
        value: "Engenheira Florestal",
        label: "Engenheira Florestal",
    },
    {
        value: "Engenheiro Florestal",
        label: "Engenheiro florestal",
    },
];

const initialValues = {
    Nome: "",
    Formação: "",
    Cargo: "",
    Telefone: "(31) 3612-3950",
    TelefoneOpcional: "",
    Email: "contato@sif.org.br",
    model: "0",
};

const onFinishFailed = (values: any) => {
    console.log(values);
};

const validateMessages = {
    required: "${label} é obrigatório!",
};

const SignatureForm = (props: any) => {
    return (
        <Form
            layout="vertical"
            wrapperCol={{ xs: 24 }}
            onFinish={props.onFinish}
            onFinishFailed={onFinishFailed}
            validateMessages={validateMessages}
            initialValues={initialValues}>
            <Form.Item name="Nome" className="form-item" rules={[{ required: true }]}>
                <Input className="input" addonBefore="Nome" placeholder="Nome" />
            </Form.Item>

            <Form.Item name="Cargo" className="form-item" rules={[{ required: true }]}>
                <Input className="input" addonBefore="Cargo" placeholder="Ex: Suporte do setor de informática" />
            </Form.Item>

            <Form.Item name="Email" className="form-item" rules={[{ required: true }]}>
                <Input className="input" addonBefore="E-mail" placeholder="E-mail" />
            </Form.Item>

            <Form.Item name="Telefone" className="form-item" rules={[{ required: true }]}>
                <Input className="input" addonBefore="Telefone de contato" placeholder="" />
            </Form.Item>

            <Form.Item name="TelefoneOpcional" className="form-item">
                <Input className="input" addonBefore="Celular Corporativo" placeholder="(00) 00000-0000" />
            </Form.Item>

            <Input.Group compact>
                <Input disabled className="label" defaultValue="Modelo" />
                <Form.Item name="model" style={{ width: "75%", marginBottom: 10 }} className="form-item" rules={[{ required: true }]}>
                    <Select options={options} style={{ width: "100%" }} />
                </Form.Item>
            </Input.Group>

            <Input.Group compact>
                <Input disabled className="label" defaultValue="Formação" />
                <Form.Item style={{ width: "75%", marginTop: 1 }} name="Formação" className="formation form-item">
                    <Radio.Group defaultValue={""} options={radioOptions} />
                </Form.Item>
            </Input.Group>

            <Form.Item className="form-item" style={{ marginTop: 10 }}>
                <Button className="clearButton" htmlType="reset">
                    Limpar
                </Button>
                <Button className="confirmButton" htmlType="submit">
                    Gerar assinatura
                </Button>
            </Form.Item>
        </Form>
    );
};

export default SignatureForm;
