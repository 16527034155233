import React from 'react';
import { UploadOutlined } from '@ant-design/icons';
import type { UploadProps } from 'antd';
import { Button, message, Upload } from 'antd';
import { getCookie } from 'typescript-cookie';

const props: UploadProps = {
    name: 'file',
    action: 'https://news.api.sif.org.br/api/journals',
    headers: {
      authorization: `Bearer ${getCookie('token')}`,
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

const Journal = () => {
    return (
        <div style={{display: 'flex', justifyContent: 'center'}}>
            <Upload {...props} >
                <Button icon={<UploadOutlined />}>Clique aqui para subir o Quinzenal</Button>
            </Upload>
        </div>
    );
}
 
export default Journal;