import React, { useEffect, useState } from 'react';
import getEmployeeWithoutUser from '../../../service/api/employee/getEmployeeWithoutUser';
import { DeleteOutlined } from "@ant-design/icons";
import './styles.css';
import { Button } from 'antd';
import deleteEmployee from '../../../service/api/employee/removeEmployee';


const EmployeeList = () => {
    
    const [employees, setEmployees] = useState([]);
    
    const employeeRemove = (id: any) => {
        console.log(id);
        deleteEmployee(id);
    }

    useEffect(() => {
        getEmployeeWithoutUser().then((data) => setEmployees(data));
        console.log(employees);
    }, []);

    return (
        <div>
            {
                employees.map((employee) => {
                    if (employee['user'] == null) {
                        return (
                            <div className='delete-row' key={employee['id']}>
                                <div>
                                    {employee['name']}
                                </div>
                                <div>
                                    <Button onClick={() => employeeRemove(employee['id'])}>
                                        <DeleteOutlined/>
                                    </Button>
                                </div>
                            </div>
                        );
                    }
                })
            }
        </div>
    );
}
 
export default EmployeeList;