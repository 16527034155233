import React, { useEffect } from "react";
import axios from "axios";
import { getCookie, removeCookie } from "typescript-cookie";
import refresh from "../../service/api/auth/refresh";
import me from "../../service/api/user/me";
import { defaultUser, useUserContext } from "../../service/context/user/UserContext";
import Layout from "../../components/app/Layout/Layout";
import { Navigate } from "react-router-dom";

const Index = (props: any) => {
    const { setUser } = useUserContext();

    useEffect(() => {
        setInterval(() => {
            refresh().then(() => {
                axios.defaults.headers.common["Authorization"] = "Bearer " + getCookie("token");
            });
        }, 1200000);
        me()
            .then((res) => {
                if (res.activated == false) {
                    setUser({
                        ...defaultUser,
                        changed: true,
                    });
                } else {
                    setUser({
                        id: res.id,
                        name: res.name,
                        permission: res.permission,
                        payment_permission: res.payment_permission,
                        employee_id: res.employee_id,
                        email: res.email,
                        activated: res.activated,
                        changed: true,
                    });
                }
            })
            .catch((error) => {
                removeCookie("token");
                <Navigate to={"/login"} />;
            });
    }, []);

    return <Layout></Layout>;
};

export default Index;
