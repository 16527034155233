import axios from "axios";
import { getCookie } from "typescript-cookie";

type MeResponse = {
    id: number;
    created_at: string;
    updated_at: string;
    email: string;
    activated: number;
    permission: number;
    employee_id: number;
    name: string;
};

const me = async () => {
    const url = "https://prosif.api.user.sif.org.br/api/me";
    // const url = "http://localhost:8000/api/me";

    try {
        const { data } = await axios.post(url);

        return data;
    } catch (err: any) {
        throw new Error(err);
    }
};

export default me;
