import React, { useState, useEffect, useRef } from "react";
import type { InputRef } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import { Select, Form, Typography } from "antd";
import getSchedule from "../../../service/api/user/getSchedule";
import getColumnProps from "../../../service/antd/table/columnsProps";
import { getOnCellProps, EditableCell } from "../../../service/antd/table/editableCell";
import { getColumnSearchProps } from "../../../service/antd/table/searchAndFilters";
import { EditOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";
import getEmployees from "../../../service/api/employee/getEmployees";
import changeSchedule from "../../../service/api/schedule/changeSchedule";
import { isDataView } from "util/types";

interface ScheduleType {
    key: string;
    station: number;
    employee: string;
    day: string;
    turn: string;
}

const Schedule = () => {
    const [fullData, setFullData] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [editingKey, setEditingKey] = useState("");
    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const [day, setDay] = useState("Segunda");
    const [selectOptions, setOptions] = useState([]);
    const [form] = Form.useForm();
    const searchInput = useRef<InputRef>(null);

    const isEditing = (record: ScheduleType) => record.key === editingKey;

    const save = async (key: React.Key) => {
        try {
            setLoading(true);
            changeSchedule(form.getFieldValue('employee'), key).then(() => {
                getSchedule().then((data) => {
                    const startData = data.map((element: any) => {
                        return {
                            employee: element.employee ? element.employee.name : "",
                            day: element.day,
                            station: element.station,
                            turn: element.turn,
                            key: element.id
                        };
                    });
                    setFullData(startData);
                    const formatData = startData.filter((element: any) => {
                        return element.day === day;
                    });
                    setTableData(formatData);
                    setLoading(false);
                });
                setLoading(false);
                setEditingKey("");
            })
        } catch (errInfo) {
            console.log("Validate Failed:", errInfo);
        }
    };

    const edit = (record: Partial<ScheduleType> & { key: React.Key }) => {
        form.setFieldsValue({ name: "", age: "", address: "", ...record });
        setEditingKey(record.key);
    };

    const cancel = () => {
        setEditingKey("");
    };

    useEffect(() => {
        getSchedule().then((data) => {
            const startData = data.map((element: any) => {
                return {
                    employee: element.employee ? element.employee.name : "",
                    day: element.day,
                    station: element.station,
                    turn: element.turn,
                    key: element.id
                };
            });
            setFullData(startData);
            const formatData = startData.filter((element: any) => {
                return element.day === day;
            });
            setTableData(formatData);
            setLoading(false);
        });
        getEmployees().then((data) => {
            const formattedData = data.map((element: any) => {
                return {
                    label: element.name,
                    value: element.id,
                }
            });
            formattedData.sort((a:any, b:any) => {
                if (a.label > b.label) return 1;
                if (b.label > a.label) return -1;
                return 0;
            })
            setOptions(formattedData);
        });
        changeSchedule(1, 30);
    }, []);

    const columns: ColumnsType<ScheduleType> = [
        {
            ...getColumnProps("Nome", "employee"),
            ...getColumnSearchProps("employee", searchInput, searchText, searchedColumn, setSearchedColumn, setSearchText),
            onCell: (record: ScheduleType) => ({
                ...getOnCellProps("employee", record, "employee", "select", editingKey, isEditing, selectOptions, "employee"),
            }),
            width: "60%",
        },
        {
            ...getColumnProps("Turno", "turn"),
            render: (_: any, record: ScheduleType) => {
                return record.turn ? "Tarde" : "Manhã";
            },
            onFilter: (value, record: ScheduleType) => record.turn == value,
            filters: [
                {
                    text: "Tarde",
                    value: true,
                },
                {
                    text: "Manhã",
                    value: false,
                },
            ],
            width: "15%",
            align: "center",
        },
        {
            ...getColumnProps("Estação", "station"),
            width: "15%",
            align: "center",
        },
        {
            ...getColumnProps("Ações", "actions"),
            render: (_: any, record: ScheduleType) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
                        <Typography.Link onClick={() => save(record.key)} style={{ marginRight: 8, float: "left" }}>
                            <CheckOutlined />
                        </Typography.Link>
                        <Typography.Link onClick={cancel} style={{ float: "right" }}>
                            <CloseOutlined />
                        </Typography.Link>
                    </span>
                ) : (
                    <Typography.Link disabled={editingKey !== ""} onClick={() => edit(record)}>
                        <EditOutlined style={{ width: "100%" }} />
                    </Typography.Link>
                );
            },
            align: "center",
            width: "10%",
            fixed: "right",
        },
    ];

    const options = [
        {
            value: "Segunda",
            label: "Segunda",
        },
        {
            value: "Terça",
            label: "Terça",
        },
        {
            value: "Quarta",
            label: "Quarta",
        },
        {
            value: "Quinta",
            label: "Quinta",
        },
        {
            value: "Sexta",
            label: "Sexta",
        },
    ];

    const handleChange = (value: any) => {
        setDay(value);
        const newData = fullData.filter((element: any, index) => {
            return element.day === value;
        });

        console.log(newData);
        setTableData(newData);
    };

    return (
        <>
            <Select defaultValue="Segunda" options={options} style={{ width: "100%", marginBottom: "20px", borderRadius: "4px" }} onChange={handleChange}></Select>
            <Form form={form} component={false}>
                <Table
                    dataSource={tableData}
                    components={{
                        body: {
                            cell: EditableCell,
                        },
                    }}
                    columns={columns}
                    loading={loading}></Table>
            </Form>
        </>
    );
};

export default Schedule;
