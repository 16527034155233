import axios from "axios";
import { getCookie } from "typescript-cookie";
import React, { useState } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./styles/globalStyle.css";
import { UserContent, UserContext } from "./service/context/user/UserContext";

axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.common["Authorization"] = "Bearer " + getCookie("token");

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log)        )
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
