import React, { useRef, useState, useEffect } from "react";
import { Button, Divider, Form, Input, InputRef, Select, Space } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import "../../../styles/formStyles.css";
import "./styles.css";
import getEmployeeWithoutUser from "../../../service/api/employee/getEmployeeWithoutUser";
import addEmployee from "../../../service/api/employee/addEmployee";
import createUser from "../../../service/api/user/createUser";

const permissionsOptions = [
    {
        value: 0,
        label: "Sem acesso",
    },
    {
        value: 1,
        label: "Acesso padrão",
    },
    {
        value: 5,
        label: "Acesso administrativo",
    },
];

const initialValues = {
    permission: 0,
    paymentPermission: 0,
};

const UserForm = (props: any) => {
    const [employeeOptions, setEmployeeOptions] = useState([{}]);
    const [name, setName] = useState("");
    const inputRef = useRef<InputRef>(null);

    useEffect(() => {
        getEmployeeWithoutUser().then((res) => {
            let options = res.map((e: any) => {
                return {
                    value: e.id,
                    label: e.name,
                };
            });
            setEmployeeOptions(options);
        });
    }, []);

    useEffect(() => {}, [employeeOptions]);

    const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    };

    const addItem = (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
        e.preventDefault();
        if (name !== "") {
            addEmployee(name).then((res) => {
                setEmployeeOptions([
                    ...employeeOptions,
                    {
                        value: res.id,
                        label: res.name,
                    },
                ]);
            });
            setName("");
            setTimeout(() => {
                inputRef.current?.focus();
            }, 0);
        }
    };

    const onFinish = (values: any) => {
        const permissions = {
            user: values.permission,
            payment: values.paymentPermission,
        };
        createUser(values.email, values.password, permissions, values.employee)
            .then((res) => {
                const r = res;
                props.handleAlert("success");
            })
            .catch((err) => {
                const e = err;
                props.handleAlert("error");
            });
    };

    return (
        <Form
            layout="vertical"
            wrapperCol={{ xs: 24 }}
            onFinish={onFinish}
            // onFinishFailed={onFinishFailed}
            // validateMessages={validateMessages}
            initialValues={initialValues}>
            <Divider orientation="left">
                <span className="formDivider">Credenciais</span>
            </Divider>

            <Input.Group compact>
                <Input disabled className="label" defaultValue="Funcionário" />
                <Form.Item name="employee" style={{ width: "75%", marginBottom: 10 }} className="form-item" rules={[{ required: true }]}>
                    <Select
                        style={{ width: "100%" }}
                        dropdownRender={(menu) => (
                            <>
                                <Space style={{ paddingBottom: "10px" }}>
                                    <Input placeholder="Nome" value={name} onChange={onNameChange} />
                                    <Button type="text" icon={<PlusOutlined />} onClick={addItem}>
                                        Adicionar
                                    </Button>
                                </Space>
                                {menu}
                                <Divider style={{ margin: "8px 0" }} />
                            </>
                        )}
                        options={employeeOptions}
                    />
                </Form.Item>
            </Input.Group>

            <Form.Item name="email" className="form-item" rules={[{ required: true, message: "O E-mail é obrigatório" }]}>
                <Input className="input" addonBefore="E-mail" placeholder="E-mail" />
            </Form.Item>

            <Form.Item name="password" rules={[{ required: true, message: "Digite sua senha" }]}>
                <Input.Password addonBefore="Senha" placeholder="Senha" />
            </Form.Item>

            <Divider orientation="left">
                <span className="formDivider">Permissões nos subsistemas</span>
            </Divider>

            <Input.Group compact>
                <Input disabled className="label" defaultValue="Usuários" />
                <Form.Item name="permission" style={{ width: "75%", marginBottom: 10 }} className="form-item" rules={[{ required: true }]}>
                    <Select options={permissionsOptions} style={{ width: "100%" }} />
                </Form.Item>
            </Input.Group>

            <Input.Group compact>
                <Input disabled className="label" defaultValue="Recheque" />
                <Form.Item name="paymentPermission" style={{ width: "75%", marginBottom: 10 }} className="form-item" rules={[{ required: true }]}>
                    <Select options={permissionsOptions} style={{ width: "100%" }} />
                </Form.Item>
            </Input.Group>

            <div className="center">
                <Button className="confirmButton registerButton" htmlType="submit">
                    Cadastrar
                </Button>
            </div>
        </Form>
    );
};

export default UserForm;
