import React from "react";
import { Row, Col, Skeleton } from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import "./styles.css";
import theme from "../../../styles/theme";
import { useUserContext } from "../../../service/context/user/UserContext";

const UserArea = (props: any) => {
    const { user } = useUserContext();

    return (
        <Row className="userArea">
            <Col>{user.name}</Col>
            <Col>
                <LogoutOutlined style={{ marginLeft: "10px", color: theme.tertiary, fontSize: "20px" }} />
            </Col>
        </Row>
    );
};

export default UserArea;
