import axios from "axios";
import { setCookie } from "typescript-cookie";

const refresh = async () => {
    const url = "https://prosif.api.user.sif.org.br/api/refresh";
    // const url = "http://localhost:8000/api/refresh";

    try {
        const { data } = await axios.post(url);

        setCookie("token", data.token, { expires: 1, secure: true });
    } catch {
        // alert("Tente novamente mais tarde a");
    }
};

export default refresh;
