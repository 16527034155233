import axios from "axios";

const changeSchedule = async (id: any, key: any) => {
    const url = "https://prosif.api.user.sif.org.br/api/schedule/" + key;

    try {
        const { data } = await axios.patch(url, {
            employee_id: id
        });

        return data;
    } catch (err) {
        console.log(err);
    }
};

export default changeSchedule;
