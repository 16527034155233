import axios from "axios";

const getSchedule = async () => {
    // const url = "https://prosif.api.user.sif.org.br/api/user";
    const url = "https://prosif.api.user.sif.org.br/api/schedule";

    try {
        const { data } = await axios.get(url);

        return data;
    } catch (err) {
        // console.log(err);
    }
};

export default getSchedule;
