import axios from "axios";

type Permissions = {
    user: number;
    payment: number;
};

type createUserResponse = {
    email: string;
    activated: string;
    permission: string;
    payment_permission: string;
    employee_id: string;
    updated_at: string;
    created_at: string;
    id: number;
};

const createUser = async (email: string, password: string, permissions: Permissions, employee: string) => {
    const url = "https://prosif.api.user.sif.org.br/api/user";
    // const url = "http://localhost:8000/api/user";

    try {
        const { data } = await axios.post(url, {
            email: email,
            password: password,
            permission: permissions.user,
            payment_permission: permissions.payment,
            activated: 1,
            employee_id: employee,
        });

        return data;
    } catch (err) {
        throw err;
    }
};

export default createUser;
