import React from "react";
import { UserSwitchOutlined, UserAddOutlined, UnlockOutlined } from "@ant-design/icons";
import type { MenuProps } from "antd";
type MenuItem = Required<MenuProps>["items"][number];

const getSubMenuItems = (
    label: React.ReactNode,
    key: React.Key,
    onClickChangeSubMenu: any,
    redirectTo: string,
    icon?: React.ReactNode,
    disabled?: boolean
) => {
    return {
        label,
        key,
        icon,
        disabled,
        onClick: () => onClickChangeSubMenu(redirectTo),
    };
};

export default getSubMenuItems;
