import axios from "axios";
import { Navigate } from "react-router-dom";
import { setCookie, getCookie } from "typescript-cookie";

const logout = async () => {
    const url = "https://prosif.api.user.sif.org.br/api/logout";
    const redirectUrl = "https://prosif.sif.org.br/login";
    // const url = "http://localhost:8000/api/logout";
    // const redirectUrl = "http://localhost:3000/login";

    try {
        const res = await axios.post(url);

        setCookie("token", "", { secure: true });
        return <Navigate to={"/login"} />;
    } catch {
        alert("Tente novamente mais tarde");
    }
};

export default logout;
