import axios from "axios";

const deleteEmployee = async (id: any) => {
    const url = "https://prosif.api.user.sif.org.br/api/employee/" + id;

    try {
        const { data } = await axios.delete(url);

        return data;
    } catch (err) {
        console.log(err);
    }
};

export default deleteEmployee;
