import React, { useEffect, useState } from "react";
import { Button } from "antd";
import theme from "../../../styles/theme";
import "../../../styles/contentStyles.css";
import "./styles.css";
import Swal from "sweetalert2";
import PaymentCard from "../PaymentCard/PaymentCard";
import PaymentList from "../PaymentList/PaymentList";

const PaymentContent = () => {
    const [name, setName] = useState("");
    const [formation, setFormation] = useState("");
    const [position, setPosition] = useState("");
    const [email, setEmail] = useState("");
    const [number, setNumber] = useState("");
    const [optNumber, setOptNumber] = useState("");
    const [model, setModel] = useState("");
    const [visibility, setVisibility] = useState(false);
    const [display, setDisplay] = useState("none");

    const handleSubmit = (values: any) => {

    };

    return (
        // <div className="content">
        //     <div className="content-title" style={{ backgroundColor: theme.secondary }}>
        //         <h3>Recheque</h3>
        //     </div>
        //     <div className="main">
        //         <PaymentList/>
        //     </div>
        // </div>
        <></>
    );
};

export default PaymentContent;
