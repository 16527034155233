import React from "react";
import { useState } from "react";
import { Form, Input, Button, Spin } from "antd";
import { LockOutlined, UserOutlined, LoadingOutlined } from "@ant-design/icons";
import Brand from "../Brand/Brand";
import theme from "../../../styles/theme";
import "./styles.css";
import login from "../../../service/api/auth/login";
import { getCookie } from "typescript-cookie";

const Auth = () => {
    const [showProgress, setProgress] = useState("none");
    const [showError, setError] = useState("none");

    const antIcon = <LoadingOutlined style={{ fontSize: 36 }} spin />;

    const onFinish = (values: any) => {
        login(values.email, values.password, setProgress, setError);
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log("Failed:", errorInfo);
    };

    return (
        <>
            <div id="authArea" style={{ backgroundColor: theme.secondary }}>
                <Brand size={0} />
                <Form
                    name="basic"
                    className="login-form"
                    wrapperCol={{ xs: { span: 20 }, sm: { offset: 2, span: 20 } }}
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="on">
                    <Form.Item name="email" rules={[{ required: true, message: "Digite seu e-mail" }]}>
                        <Input prefix={<UserOutlined className="site-form-item-icon" />} className="textInput" placeholder="E-mail" />
                    </Form.Item>

                    <Form.Item name="password" rules={[{ required: true, message: "Digite sua senha" }]}>
                        <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} className="textInput" placeholder="Senha" />
                    </Form.Item>

                    <Form.Item wrapperCol={{ xs: { span: 8 }, sm: { offset: 8, span: 8 } }}>
                        <Button
                            type="primary"
                            style={{ background: theme.gradientGreen, cursor: "pointer" }}
                            className="login-form-button loginBtn"
                            htmlType="submit">
                            Login
                        </Button>
                    </Form.Item>
                </Form>
                <Spin size="large" indicator={antIcon} style={{ display: showProgress, color: theme.staticGreen }} />
                <div className="errorMsg" style={{ display: showError }}>
                    Usuário ou senha inválidos
                </div>
            </div>
        </>
    );
};

export default Auth;
